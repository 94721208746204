import './App.css';
import React, { Suspense, lazy } from 'react';
import { Routes, Route } from "react-router-dom";
import appskill from "./assets/appskill.png"
import loading from "./assets/loding.gif"
// importing components
import Nav from "./Components/Header/nav";
import Footer from './Components/Footer/footer';

// importing Routes
const Home = lazy(() => import('./Pages/Home/home'));
const About = lazy(() => import('./Pages/AboutUs/about'));
const Contact = lazy(() => import('./Pages/ContactUs/contact'));
const Staffing = lazy(() => import('./Pages/ITStaffing/staffing'));
const Services = lazy(() => import('./Pages/OurServices/services'));
const PageNotFound = lazy(() => import('./Pages/404Page/errorpage'));
function App() {
  return (
    <div className="App">
      <Nav />
      <Suspense fallback={<div className='LazyLoding'><img src={appskill} alt={appskill} /><img src={loading} alt={loading} width="100" /></div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/staffing" element={<Staffing />} />
          <Route path="/our-services" element={<Services />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;
