import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import appskill from "../../assets/appskill.png";
import Sidenav from './sidenav';
const Nav = () => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    return (
        <div className='navbar'>
            <div className="nav">
                <div className="navBrand"><Link to="/"><img src={appskill} alt={appskill} /></Link></div>
                <div className="navItems">
                    <ul>
                        <li><Link className={splitLocation[1] === "" ? "activeMenu" : "nav-link"} to="/">Home</Link></li>
                        <li><Link className={splitLocation[1] === "about-us" ? "activeMenu" : "nav-link"} to="/about-us">About Us</Link></li>
                        <li><Link className={splitLocation[1] === "it-staffing" ? "activeMenu" : "nav-link"} to="/staffing">Staffing</Link></li>
                        <li><Link className={splitLocation[1] === "our-services" ? "activeMenu" : "nav-link"} to="/our-services" >Our Service</Link></li>
                        <li><Link className={splitLocation[1] === "contact-us" ? "activeMenu" : "nav-link"} to="/contact-us">Contact Us</Link></li>
                    </ul>
                </div>
                <div className="sideWrapper">
                    <Sidenav />
                </div>
            </div>
        </div>
    )
}

export default Nav;