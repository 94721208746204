import React from 'react'
import appskill from '../../assets/appskill.png';
import location from '../../assets/location.png';
import email from '../../assets/email.png';
import phone from '../../assets/phone.png';
import { Link } from 'react-router-dom';
const footer = () => {
    return (
        <div className='fotterWrapper'>
            <div className="footerWidgets">
                <div className='widget1'>
                    <img src={appskill} alt={appskill} />
                    <p>
                        Appskill is an Offshore Outsourcing Centre based in Nepal which is fully owned and operated by a leading Australian recruitment and staffing company operating since 2007. We offer you the best of both worlds as our recruiters based in Australia will help you hire the best remote staff in Nepal at a fraction of the cost of hiring them in Australia while we also take responsibility for your staffing success and IP protection.
                    </p>
                </div>
                <div className='widget2'>
                    <h1>Quick Links</h1>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about-us">About Us</Link></li>
                        <li><Link to="/staffing">Staffing</Link></li>
                        <li><Link to="/our-services" >Our Service</Link></li>
                        <li><Link to="/contact-us">Contact Us</Link></li>
                    </ul>
                </div>
                <div className='widget3'>
                    <h1>Services</h1>
                    <ul>
                        <li><Link to="/our-services" >App Development</Link></li>
                        <li><Link to="/our-services" >Web Development</Link></li>
                        <li><Link to="/our-services" >Graphic Designing</Link></li>
                        <li><Link to="/our-services" >Digital Marketing</Link></li>
                    </ul>
                </div>
                <div className='widget4'>
                    <h1>Contact Us</h1>
                    <ul>
                        <li><img src={email} alt={email} /> <div>service@studyandwork.com.au</div></li>
                        <li><img src={phone} alt={phone} /> <div>+61 421 338 592</div></li>
                        <li><img src={location} alt={location} /><div>Parramatta , Australia</div></li>
                    </ul>
                </div>
                <div className='widget5'>
                    <h1>Follow Us</h1>
                    <div className="subscribe">
                        <input type="text" name="" placeholder="Join Our Newsletter" id="" />
                        <button>Subscribe</button>
                    </div>
                </div>
            </div>
            <div className="footerCopy">
                {/* <center><div className='footer-underline'></div></center> */}
                <p>© 2022 AppSkill.com. All rights reserved.</p>
            </div>
        </div>
    )
}

export default footer