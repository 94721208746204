import React,{useState} from 'react'
import { Link} from 'react-router-dom';
import{GiHamburgerMenu} from "react-icons/gi"
import{IoClose} from "react-icons/io5"
const Sidenav = () => {
  const [showSidebar, setShowSidebar] = useState(true);

  return (
    <div className="sideNav">
      <button className='hamburgerOpen' onClick={()=>setShowSidebar(!showSidebar)}><GiHamburgerMenu/></button>
    <div className={showSidebar?"sideNavbar":"sideNavbar sideOpen"}>
      <div onClick={()=>setShowSidebar(!showSidebar)}>
        <button className="hamburgerClose"><IoClose/></button>
        <div className="sidenavItems">
                    <ol>
                        <li><Link to="/">Home</Link></li>
                        <li><Link  to="/about-us">About Us</Link></li>
                        <li><Link to="/staffing">Staffing</Link></li>
                        <li><Link to="/our-services" >Our Service</Link></li>
                        <li><Link  to="/contact-us">Contact Us</Link></li>
                    </ol>
                </div>
      </div>
    </div>
    </div>
  )
}

export default Sidenav